<template>
  <div>
    <div
      class="flex-grow flex flex-col m-2 p-6 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
    >
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-gray-800">{{ title }}</h3>
        </div>
      </div>
      <div class="flex flex-wrap items-center mt-5">
        <div
          v-if="userType == 'Admin' || userType == 'StockManager' ||  userType == 'CEO'"
          class="relative w-full px-4 max-w-full flex-grow flex-1"
        >
          <div
            class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="category" class="bg-white text-gray-700 px-1"
                  >{{$t('seller')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <v-select
                v-model="timeserchsellert"
                :options="sellers"
                label="fullName"
                @input="ChangeSeller"
              ></v-select>
            </p>
         </div>  
       </div>
        <div  v-if="action=='stockhistories' && (userType == 'Admin' || userType == 'CEO' || userType == 'StockManager' || userType == 'Seller')" class="relative w-full px-4 max-w-full flex-grow flex-1">
         <div class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
           <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
              <label for="product" class="bg-white text-gray-700 px-1">{{$t('product')}} : <span :class="$colors.required">*</span></label>
              </p>
            </div>
            <p>
              <v-select  v-model="timeserchprocuct" :options="products" label="name" @input="ChangeProduct" ></v-select>    
            </p>
         </div>  
       </div> 
       <div v-if="action=='revenu' || action=='stockhistories' || action=='expeditions'" class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
         <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="status" class="bg-white text-gray-700 px-1"
                  >{{$t('date')}} : <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
           <p>  
          <select :disabled="action=='stockhistories' && timeserchprocuct=='...'"
                id="type"
                :name="action"
                autocomplete="type"
                @change="ChangeTypeDateRevenu($event)"
                v-model="timeserchtype"
                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
              >
                >
                <option class="bg-white text-gray-600" value="today">
                  {{$t('today')}}
                </option>
                <option class="bg-white text-gray-600" value="thisweek">
                  {{$t('this_week')}}
                </option>
                <option class="bg-white text-gray-600" value="lastweek">
                  {{$t('last_week')}}
                </option>
                <option class="bg-white text-gray-600" value="thismonth">
                  {{$t('this_month')}}
                </option>
                <option class="bg-white text-gray-600" value="lastmonth">
                  {{$t('last_month')}}
                </option>
                <option class="bg-white text-gray-600" value="thisyear">
                  {{$t('this_year')}}
                </option>
                  <option value="betweendate" class="bg-white text-gray-600"> {{$t('between_two_date')}} </option>
              </select>
            </p>
          </div>
        </div>
     
      </div>
       <div v-if="filter && timeserchtype=='betweendate'" class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <div  class="inline-block mt-4">
              <div class="inline-block mt-2 w-2/5 pr-1">
                <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">From <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="fromDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="from" type="date"> </p>
                </div>
              </div>
                <div class="inline-block mt-2 w-2/5 pr-1">
                  <div class="border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"><p><label for="date" class="bg-white text-gray-700 px-1">To <span class="text-blue-500">*</span></label></p></div>
                    <p>  <input  v-model="toDate" class="w-full px-1 py-1 text-gray-700 bg-gray-200 rounded" id="to" type="date"> </p>
                </div>
              </div>
              <div class="inline-block mt-2 w-1/5 pr-1">
                  <button @click="FindRecordRevenus()"  :disabled="fromDate==null || toDate==null" class="w-full px-2 py-2 text-white font-light tracking-wider bg-gray-900 rounded">Find</button>
              </div>
          </div>
        </div>
      <canvas :id="id" style="height: 300px"></canvas>
    </div>
  </div>
</template>



<script>
import Chart from "chart.js";
//import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  props: ["currency","sellers","countOrders","from","to","filter","sumrevenuorder","sumrevenusPending","sumrevenus",'timeserchwarhouse',"chartData","chartData1","chartData2","label", "options", "Labels", "labelstring","timeserch","timeserchseller","action","id",'title','type','userType','timeserchtockhistoriesproducts','products'],
  data() {
    return {
      myChart: "",
      timeserchtype:this.timeserch,
      timeserchsellert:this.timeserchseller,
      timeserchprocuct:this.timeserchtockhistoriesproducts,
      warhouse:this.timeserchwarhouse,
      Datasets:[],
      fromDate:this.from,
      toDate:this.to,
       warehouses:[]
    };
  },
  async mounted() {
    await this.drawChart(this.chartData, this.Labels, this.labelstring);
     await this.getWarehouses();
  },
  methods: {
    async getWarehouses(){
      const filter = {limit:10};
      const res = await this.$server.search("warehouses",filter);
      if (res.content.results) {
          this.warehouses = res.content.results;
      } else this.warehouses = [];
    },
    FindRecordRevenus: function () {
      this.$emit("FindRecordRevenus",this.fromDate,this.toDate);
    },
    ChangeTypeDateRevenu: function (val) {
      this.$emit("ChangeTypeDateRevenu", val);
    },
    ChangeSeller: function (val) {
      this.$emit("ChangeSeller", val);
    },
    ChangeProduct: function (val) {
       this.$emit('ChangeProduct',val);
    },
    drawChart(chartData, labels, labelstring) {
      var ctx = document.getElementById(this.id);
      var title='';
      if(this.action=='revenu'){title='Total Orders: '+this.countOrders}
       this.Datasets=[];
       if(this.userType=='Admin' || this.userType == 'CEO' || (this.userType=='Seller' && this.action!='revenu' )){ 
         let titleLabel=this.label;
         if(this.action=='revenu'){ titleLabel=this.label+" ("+this.sumrevenus+" "+this.currency+")";}
           if(this.action!='stockhistories'){
              this.Datasets.push( {
                  label: titleLabel,
                  backgroundColor: "#36a2eb",
                  borderColor: "#36a2eb",
                  data: chartData,
                  fill: false,
                  barThickness: 20,
                  ticks: {
                    fontSize: 10,
                  },
                });
         }
       }
      if(this.action=='revenu'){
        this.Datasets.push( {
              label: "Revenus Orders ("+this.sumrevenuorder+" "+this.currency+")",
              backgroundColor: "#01a653",
              borderColor: "#01a653",
              data: this.chartData1,
              fill: false,
              barThickness: 20,
              ticks: {
                fontSize: 10,
              },
            });
            this.Datasets.push( {
              label: "Revenus à payer ("+this.sumrevenusPending+" "+this.currency+")",
              backgroundColor: "#ff6384",
              borderColor: "#ff6384",
              data: this.chartData2,
              fill: false,
              barThickness: 20,
              ticks: {
                fontSize: 10,
              },
            });
      }  
        if(this.action=='stockhistories'){
           this.Datasets.push( {
                  label: 'Quantity in stock Added',
                   backgroundColor: "#36a2eb",
                  borderColor: "#36a2eb",
                  data: this.chartData,
                  fill: false,
                  tension:0,
                  barThickness: 20,
                  ticks: {
                    fontSize: 10,
                  },
                });
            this.Datasets.push( {
                  label: 'Quantity in stock Out',
                  backgroundColor: "#ff6384",
                  borderColor: "#ff6384",
                  data: this.chartData1,
                  fill: false,
                   tension:0,
                  barThickness: 20,
                  ticks: {
                    fontSize: 10,
                  },
                });
       }
      if(this.action=='expeditions'){
        this.Datasets.push( {
              label: "Number of expeditions",
              backgroundColor: "#ff6384",
              borderColor: "#ff6384",
              data: this.chartData1,
              fill: false,
              barThickness: 20,
              ticks: {
                fontSize: 10,
              },
            });
      }          
      this.myChart = new Chart(ctx, {
        type: this.type,
        data: {
          labels: labels,
          datasets:this.Datasets,
        },
         options: {
          responsive: true,
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                  var allData = data.datasets[tooltipItem.datasetIndex].data;
                  var tooltipLabel = data.labels[tooltipItem.index];
                  var tooltipData = allData[tooltipItem.index];
                  var total = 0;
                  for (var i in allData) {
                    total += allData[i];
                  }
                  var tooltipPercentage = Math.round((tooltipData / total) * 100);
                  return tooltipLabel + ': ' + tooltipData;
                }
              }
          },
        title: {
          display: true,
          text: title,
          position: 'top',
          fontSize: 16,
          fontColor: '#111',
          padding: 20
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 20,
                fontColor: '#111',
                padding: 15
            }
        },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  watch: {
    Labels: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.chartData, this.Labels, this.labelstring);
    },
    chartData: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.chartData, this.Labels, this.labelstring);
    },
    labelString: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.chartData, this.Labels, this.labelstring);
    },
  },
};
</script>
<style scoped>
body {
  font-family: "IBM Plex Mono", sans-serif;
}
[x-cloak] {
  display: none;
}
.vs__dropdown-toggle {
  border: none;
}
.line {
  background: repeating-linear-gradient(
    to bottom,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 8%
  );
}
.tick {
  background: repeating-linear-gradient(
    to right,
    #eee,
    #eee 1px,
    #fff 1px,
    #fff 5%
  );
}
</style>